import Box from "@mui/joy/Box";
import React from "react";
import ColorToggle from "../color-toggle/ColorToggle";
import {
  Button,
  Container,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Sheet,
  styled,
  useColorScheme,
} from "@mui/joy";
import { MdMenu } from "react-icons/md";
import Logo from "../../assets/Logo-dark.svg";
import LogoWhite from "../../assets/Logo-white.svg";

import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/login-slice/login.slice";
import Notification from "../notification/Notification";

const Header = styled(Sheet)(({ theme, transparent }) => ({
  gap: 1,
  border: "none",
  display: "flex",
  flexDirection: "row",
  background: `${
    transparent ? "transparent" : theme.vars.palette.background.body
  } `,
  padding: 10,
  justifyContent: "space-between",
  alignItems: "center",
  gridColumn: "1 / -1",
  position: "fixed",
  top: 0,
  left: 0,
  overflow: "hidden",
  zIndex: 1100,
  width: "100%",
}));

function Navbar() {
  const user = useSelector((state) => state.auth.user);
  const { mode } = useColorScheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Header
          component="header"
          className="Header"
          transparent={pathname === "/login" ? 1 : 0}
          sx={{ px: { xs: 2, md: 4 } }}
        >
          <Box
            sx={{
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Link to="/">
              <img
                src={mode === "dark" ? LogoWhite : Logo}
                width="120"
                height={60}
                alt="Pons Logo"
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {user &&
              (user.user_type !== "GUEST" ||
                user?.user_type !== "INTERPRETER") && (
                <>
                  <Notification />

                  <Dropdown>
                    <MenuButton size="sm" color="neutral" variant="solid">
                      <MdMenu />
                    </MenuButton>
                    <Menu
                      sx={{ zIndex: "200000" }}
                      color="neutral"
                      variant="soft"
                    >
                      <MenuItem color="neutral" component={NavLink} to="/">
                        Home
                      </MenuItem>
                      <MenuItem component={NavLink} to="/dashboard">
                        Dashboard
                      </MenuItem>
                      <MenuItem component="a" href="/admin" target="_blank">
                        Legacy Dashboard
                      </MenuItem>
                      <MenuItem component={NavLink} to="/it">
                        IT Dep.
                      </MenuItem>
                      <MenuItem component={NavLink} to="/wfm">
                        WFM Dep.
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </>
              )}
            {user ? (
              <Dropdown>
                <MenuButton size="sm" color="neutral" variant="solid">
                  {user?.username?.slice(0, 2)}
                </MenuButton>
                <Menu sx={{ zIndex: "200000" }} color="neutral" variant="soft">
                  <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
                </Menu>
              </Dropdown>
            ) : (
              <>
                <Link to="/login">
                  <Button size="sm" color="neutral" variant="soft">
                    Login
                  </Button>
                </Link>
              </>
            )}
            <ColorToggle />
          </Box>
        </Header>
      </Container>
    </>
  );
}

export default Navbar;
