import { extendTheme } from "@mui/joy/styles";

export default extendTheme({
  fontFamily: {},
  colorSchemes: {
    light: {
      palette: {
        text: {},
        primary: {},
        neutral: {
          // softBg:
        },
        warning: {
          softBg: "#d6ab46",
        },
      },
    },
    dark: {
      palette: {
        text: {
          primary: "#fff",
          // secondary: "#889ce7",
        },
        background: {
          surface: "#1e2021",
          body: "#202425",
          popup: "#003A61",
          level1: "#181c1d",
        },
        primary: {
          50: "#c6eff7",
          100: "#B8EBF5",
          200: "#A6E6F2",
          300: "#90E0EF",
          400: "#48CAE4",
          500: "#184e77",
          600: "#0096C7",
          700: "#0077B6",
          800: "#013a63",
          900: "#03045E",
        },
        warning: {
          solidBg: "#b07d2e",
        },
        danger: {
          solidBg: "#fd6262",
        },
      },
    },
  },
});
