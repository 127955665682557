import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import theme from "./utils/theme";
import Navbar from "./components/navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./utils/RequireAuth";
import { useDispatch } from "react-redux";
import useMyWebSocket from "./hooks/useMyWebsocket";
import {
  setSingleActiveTicket,
  updateSingleActiveTicket,
} from "./redux/active-ticket/activeTicketSlice";
import React, { Suspense } from "react";
import Loading from "./components/loading/Loading";
import { newNotification } from "./redux/notification/notification-slice";
import HomeContainer from "./pages/home/HomeContainer";
import RequireQuality from "./utils/RequireQuality";
import EditKpi from "./pages/kpi/EditKpi";

const AddKpi = React.lazy(() => import("./pages/kpi/AddKpi"));
const InterprterProfile = React.lazy(() =>
  import("./pages/profile/InterprterProfile")
);

const Home = React.lazy(() => import("./pages/home/Home"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const DashboardContainer = React.lazy(() =>
  import("./pages/dashboard/DashboardContainer")
);
const Ticket = React.lazy(() => import("./pages/ticket/Ticket"));
const CreateTicketPage = React.lazy(() =>
  import("./pages/ticket/CreateTicketPage")
);

const ITContainer = React.lazy(() => import("./pages/it/ITContainer"));
const IT = React.lazy(() => import("./pages/it/IT"));
const ITDetail = React.lazy(() => import("./pages/it/ITDetail"));
const WFMContainer = React.lazy(() => import("./pages/wfm/WFMContainer"));
const WFM = React.lazy(() => import("./pages/wfm/WFM"));
const WFMDetail = React.lazy(() => import("./pages/wfm/WFMDetail"));

const ShareScreenContainer = React.lazy(() =>
  import("./pages/shareScreen/ShareScreenContainer")
);
const ShareScreen = React.lazy(() => import("./pages/shareScreen/ShareScreen"));
const CallEnded = React.lazy(() => import("./pages/shareScreen/CallEnded"));
const CallExceed = React.lazy(() => import("./pages/shareScreen/CallExceed"));

function App() {
  const dispatch = useDispatch();

  const _ = useMyWebSocket("ws/admins/", {
    retryOnError: true,
    share: true,
    reconnectAttempts: 100,
    onMessage: (e) => {
      const data = JSON.parse(e.data);
      if (data.message.command === "admin_new_ticket") {
        dispatch(setSingleActiveTicket(data.message.ticket));
      } else if (data.message.command === "admin_update_ticket") {
        dispatch(updateSingleActiveTicket(data.message));
      } else if (data.message.command === "notification") {
        dispatch(newNotification(data.message.data));
      } else if (data.message.command === "error") {
        toast.error(data.message.data);
      }
    },
  });

  // React.useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, []);
  // const alertUser = (e) => {
  //   e.preventDefault();
  // };

  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
        limit={5}
      />

      <CssVarsProvider
        defaultMode="light"
        disableTransitionOnChange
        theme={theme}
      >
        <CssBaseline />
        <Navbar />

        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <HomeContainer />
              </RequireAuth>
            }
          >
            <Route path="" element={<Home />} />
            <Route path="ticket/create" element={<CreateTicketPage />} />

            <Route path="ticket/:ticketId" element={<Ticket />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <DashboardContainer />
              </RequireAuth>
            }
          >
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route
            path="it"
            element={
              <RequireAuth>
                <ITContainer />
              </RequireAuth>
            }
          >
            <Route path="" element={<IT />} />
            <Route path=":slug" element={<ITDetail />} />
          </Route>
          <Route
            path="wfm"
            element={
              <RequireAuth>
                <WFMContainer />
              </RequireAuth>
            }
          >
            <Route path="" element={<WFM />} />

            <Route path=":userName" element={<WFMDetail />} />
          </Route>
          <Route
            path="share"
            element={
              <RequireQuality>
                <ShareScreenContainer />
              </RequireQuality>
            }
          >
            <Route
              path=":userName"
              element={
                <RequireQuality>
                  <ShareScreen />
                </RequireQuality>
              }
            />
            <Route path="call-end" element={<CallEnded />} />
            <Route path="call-exceed" element={<CallExceed />} />
          </Route>
          <Route
            path="kpi"
            element={
              <RequireAuth>
                <DashboardContainer />
              </RequireAuth>
            }
          >
            <Route path=":userName/add/:kpiId" element={<AddKpi />} />
            <Route path="edit/:kpiId" element={<EditKpi />} />
          </Route>
          <Route
            path="profile"
            element={
              <RequireAuth>
                <DashboardContainer />
              </RequireAuth>
            }
          >
            <Route path=":userName" element={<InterprterProfile />} />
          </Route>
        </Routes>
      </CssVarsProvider>
    </Suspense>
  );
}

export default App;
